import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import history from '../components/history/history.js';
import Home from '../views/home/index.js';

export default class Routers extends React.Component {
	render() {
		return (
			<Router history={history}>
				<Switch>
					<Route exact path="/" component={Home} />
					<Route exact path="/home" component={Home} />
				</Switch>
			</Router>
		);
	}
}