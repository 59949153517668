import React from 'react';
import Routes from './routes';
import './App.css';

export default class App extends React.Component {
	render() {
		return (
			<div>
				<Routes />
			</div>

		)
	}
}


