import React from 'react';
import './style.less';

export default class Home extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
      isFix: false, //是否置顶顶部
      menuKey: 0, //导航默认key值
      menus: ["金融架构", "业务流程", "保理服务", "关于叮当荟", "联系我们"], //导航菜单
      insuranceList: [
        //保理服务列表
        {
          icon: require("../../assets/images/insurance_1.png"),
          name: "融资服务",
        },
        {
          icon: require("../../assets/images/insurance_2.png"),
          name: "收款服务",
        },
        {
          icon: require("../../assets/images/insurance_3.png"),
          name: "财务管理",
        },
        {
          icon: require("../../assets/images/insurance_4.png"),
          name: "承担风险",
        },
      ],
      superiorityList: [
        //我们的优势
        {
          icon: require("../../assets/images/superiority_1.png"),
          name: "贴心的高质量服务",
        },
        {
          icon: require("../../assets/images/superiority_2.png"),
          name: "独特创新的互联网技术",
        },
        {
          icon: require("../../assets/images/superiority_3.png"),
          name: "保理行业领域的深耕",
        },
      ],

      contacts: [
        //联系我们
        {
          icon: require("../../assets/images/contacts_1.png"),
          name: "客服电话：",
          content: "18108359032",
        },
        {
          icon: require("../../assets/images/contacts_2.png"),
          name: "邮箱：",
          content: "cqcjxhkj@163.com",
        },
        {
          icon: require("../../assets/images/contacts_3.png"),
          name: "地址：",
          content: "重庆市江北区海尔路178号美全22世纪-B塔1507",
        },
      ],
    };
	}
	componentDidMount() {
		window.addEventListener('scroll', this.handleScroll.bind(this));
	}
	render() {
		return (
      <div>
        <div
          className={["header ", this.state.isFix ? "fixed" : null].join("")}
          id="header"
        >
          <div className="warp">
            <div className="logo">亿万嘉</div>
            <div className="menu">
              {this.state.menus.map((item, index) => {
                return (
                  <li
                    key={index}
                    className={this.state.menuKey === index ? "on" : ""}
                    onClick={this.menuChange.bind(this, index)}
                  >
                    {item}
                  </li>
                );
              })}
            </div>
          </div>
        </div>
        <div className="banner">
          <img alt="" src={require("../../assets/images/banner.png")} />
          <div className="tips">
            <p className="name">亿万嘉商业保理</p>
            <p className="info">YIWANJIA FACTORING</p>
            <p>专注提供高效的、一站式的各类消费分期场景保理服务</p>
          </div>
        </div>
        <div id="nav0">
          <div className="finance-mode warp">
            <div className="nav-title">金融架构</div>
            <div className="info">NEW FINANCIAL ARCHITECTURE</div>
            <div className="tips">
              打造金融消费、教育、美容等一站式保理服务平台
            </div>
            <img alt="" src={require("../../assets/images/finance_mode.png")} />
          </div>
        </div>
        <div id="nav1">
          <div className="business-mode">
            <img
              alt=""
              src={require("../../assets/images/business_banner.png")}
            />
            <div className="business-warp">
              <div className="nav-title">业务流程</div>
              <div className="info">BUSINESS PROCESS</div>
              <div className="tips">
                亿万嘉商业保理作为分期消费领域的引领者，专注为客户提供多场景的金融业务，其主要流程包括：项目创建、商务洽谈、走访调查、建立合作、提供服务、风控会审、融资放款、保后管理、资金对账。
              </div>
              <img
                alt=""
                src={require("../../assets/images/business_mode.png")}
              />
            </div>
          </div>
        </div>
        <div id="nav2">
          <div className="insurance-mode">
            <div className="warp">
              <div className="nav-title">保理服务</div>
              <div>INTERNATIONAL FACTORING</div>
              <div className="tips">
                保理是保付代理（Factoring）的简称，指卖方(债权人)
                在采用赊销方式向买方（债务人）销售货物或提供服务时，将所产生的应收账款债权转让给保理商，并于已经通知债务人的前提下，保理商为卖方提供集融资、账务管理、收款服务、承担买方信用风险功能于一体的综合性金融服务。
              </div>
              <div className="service">
                <div className="title">核心服务</div>
                <div className="list">
                  {this.state.insuranceList.map((item, index) => {
                    return (
                      <li className="item" key={index}>
                        <img alt={item.name} src={item.icon} />
                        <div>{item.name}</div>
                      </li>
                    );
                  })}
                </div>
              </div>
              <div className="diagram">
                <div className="title">交易关系图</div>
                <img
                  alt=""
                  src={require("../../assets/images/insurance_mode.png")}
                />
              </div>
            </div>
          </div>
        </div>
        <div id="nav3">
          <div className="about-mode">
            <div className="warp">
              <div className="nav-title">关于叮当荟</div>
              <div>ABOUT YIWANJIA</div>
              <div className="tips">
                叮当荟是亿万嘉商业保理（深圳）有限公司旗下的服务各类生活场景的金融综合平台，提供互联网各类金融信息服务、咨询服务等。拥有独立的
                APP与管理后台，凭借着多年在保理行业的深耕，打造了高效、全面的一站式运营服务。并于多家保理商、经销商、线下商户一直保持着良好的合作关系，坚持互惠共赢的理念，以实现商业保理价值最大化。
              </div>
              <div className="superiority">
                <div className="title">我们的优势</div>
                <div className="list">
                  {this.state.superiorityList.map((item, index) => {
                    return (
                      <li className="item" key={index}>
                        <img alt={item.name} src={item.icon} />
                        <div>{item.name}</div>
                      </li>
                    );
                  })}
                </div>
              </div>
              <div className="ecosphere">
                <div className="title">保理金融生态圈</div>
                <img
                  alt=""
                  src={require("../../assets/images/about_mode.png")}
                />
              </div>
            </div>
          </div>
        </div>
        <div id="nav4">
          <div className="contact-mode warp">
            <div className="nav-title">联系我们</div>
            <div>CONTACT US</div>
            <div className="contact-info">
              {/* <div className="logo"> */}
              {/* <img alt="" src={require("../../assets/images/logo.png")} /> */}
              <p>亿万嘉商业保理（深圳）有限公司</p>
              {/* </div> */}
              <div className="list">
                {this.state.contacts.map((item, index) => {
                  return (
                    <li key={index}>
                      <div className="icon">
                        <img alt="" src={item.icon} />
                      </div>
                      <div className="name">{item.name}</div>
                      <div className="content">{item.content}</div>
                    </li>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="footer">
          版权所有：2018-2022 亿万嘉商业保理（深圳）有限公司
          <a
            href="https://beian.miit.gov.cn/#/Integrated/index"
            target="_blank"
          >
            备案号：粤ICP备2022008289号-1
          </a>
          {/* {window.icpConfig} */}
        </div>
      </div>
    );
	}
	//监控滚轮滚动距离
	handleScroll(e) {
		let offsetTop = document.getElementById('header').offsetTop,
			nav0 = document.getElementById('nav0').offsetTop,
			nav1 = document.getElementById('nav1').offsetTop,
			nav2 = document.getElementById('nav2').offsetTop,
			nav3 = document.getElementById('nav3').offsetTop
		this.setState({
			isFix: window.pageYOffset > offsetTop ? true : false
		});
		if (window.pageYOffset <= nav0) {
			this.setState({
				menuKey: 0
			})
		}
		else if (window.pageYOffset <= nav1) {
			this.setState({
				menuKey: 1
			})
		}
		else if (window.pageYOffset <= nav2) {
			this.setState({
				menuKey: 2
			})
		}
		else if (window.pageYOffset <= nav3) {
			this.setState({
				menuKey: 3
			})
		}
		else if (window.pageYOffset > nav3) {
			this.setState({
				menuKey: 4
			})
		}
	}
	//切换导航
	menuChange(key) {
		this.setState({
			menuKey: key
		})
		let anchorElement = document.getElementById('nav' + key),
			headerTop = document.getElementById('header').offsetHeight;
		if (anchorElement) {
			window.scrollTo(0, anchorElement.offsetTop - headerTop);
		}
	}

}
